@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

:root {
  --thead-bg-color: #586BA4;
  --nav-width: 150px;
  --bs-link-color: #586BA4;
}
.modal {
  --bs-modal-zindex: 1155;
}
.btn-primary {
  --bs-btn-bg: #586BA4;
  --bs-btn-border-color:#586BA4;

  --bs-btn-hover-bg: #485B94;
  --bs-btn-hover-border-color: #485B94;
  --bs-btn-active-bg: #485B94;
  --bs-btn-active-border-color: #485B94;

  --bs-btn-disabled-bg: gray;
  --bs-btn-disabled-border-color: gray;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  padding: 0;
  overflow: auto;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

.form-input {
  height: 38px;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%);
  padding-left: 15px;
}

.form-label {
  margin-bottom: 0px;
}

.modal-title.h4 {
  font-size: 20px;
}

p.align-center {
  text-align: center;
}

.app-container {
  display: flex;
  min-height: calc(100vh);
}

.page-container {
  padding: 70px 40px 40px 40px;
  width: 100%;
  position: relative;
  transition: 300ms all ease-in;
  background-color: white;
}

.page-container.bookings-page {
  padding: 40px 20px 40px 20px;
}

.page-container.my-booking-page {
  padding: 40px;
}

.my-booking-page.with-page-header {
  padding-top: 90px;
}

a.logo {
  max-width: 135px;
  padding: 0 10px;
  margin-bottom: 20px;
}

a.logo img {
  width: 100%;
}

.spinner-container {
  display: inline-flex;
  align-items: center;
  font-style: italic;
  vertical-align: bottom;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }  
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 24px;
  height: 24px;
  border: 5px solid #A3A3A3; /* Light grey */
  border-top: 5px solid var(--bs-link-color); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin: 5px 5px;
}

.btn .loading-spinner, a .loading-spinner {
  margin: 0 0;
}

.rates-page .rate-description {
  font-size: 80%;
  font-style: italic;
  opacity: 0.9;
}

.rates-page {
  overflow: scroll;
}

.rates-page .data-table-container {
  max-width: none;
  min-width: 1300px;  
  max-height: calc(90vh);
}

.rates-page .rates-table {
  position: relative;
}
.rates-page .rates-table thead tr {
  /* background-color: var(--thead-bg-color); */
  color: white;
}

.rates-page .rates-table thead th {
  font-weight: normal;
  background-color: var(--thead-bg-color);
  position: sticky;
  top: -20px;
  z-index: 2;
  font-weight: normal;
}
.rates-page .rates-table thead tr th:nth-child(1) {
  left: -41px;
  z-index: 3;
}

.rates-page .rates-table thead tr td:nth-child(1),
.rates-page .rates-table thead tr th:nth-child(1) {
  width: 180px;
}

.rates-page .rates-table thead tr td:not(:nth-child(1)),
.rates-page .rates-table thead tr th:not(:nth-child(1)) {
  width: 100px;
  text-align: center;
}

.rates-page .rates-table tbody tr:hover {
  cursor: pointer;
}

.rates-page .rates-table tbody tr td:nth-child(1) {
  position:sticky;
  left: -41px;
  z-index: 1;
  background-color: lightgray;
}

.rates-page .rates-table tbody tr td:not(:nth-child(1)) {
  text-align: center;
}

.rates-page .rates-table .total-price:not(.bookable) {
  color: grey;
  opacity: 0.8;
}

.rates-page .rates-table .price-breakdown {
  font-size: 14px;
  color: grey;
  opacity: 0.8;
  font-style: italic;
}

.page-toolbar {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 7;
  background-color: #F2F2F2;
  width: calc(100% - 15px);
  padding-right: 5px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
  gap: 10px;
  transform: translate3d(0px, 0px, 0px); 
  transition: all 0.5s ease 0s;
}

.page-toolbar .date-filters {
  margin-left: -12px;
}
.page-toolbar .date-filters .dropdown-menu {
  padding: 0px;
}
.page-toolbar .date-filters .dropdown-item {
  border-bottom: 1px solid #eee;
}

.page-filters {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  width: calc(100% - 15px);
  left: 0px;
  padding-left: 30px;
  z-index: 5;
  top: 72px;
  padding: 20px 15px 10px 45px;
  background-color: white;
  transform: translate3d(0px, 0px, 0px); 
  transition: all 0.5s ease 0s;
}

.filter-group {
  max-width: 50px;
  line-height: 1.2;
  font-size: 13px;
  font-weight: 500;
}

.page-filters .calendar-period {
  margin-left: auto;
}

.table-filters {
  display: flex;
  align-items: center;
}

.table-filters .filter-group {
  max-width:70px;
}

.table-filters .react-datepicker__input-container input[type=text] {
  font-size: 14px;
}

/* .bookings-page .page-toolbar .search-field {
  flex: 1;
}

.bookings-page .page-toolbar .search-field input {
  width: 100%;
  font-style: italic;
} */

.page-toolbar .search-booking {
  flex: 1;
}

.page-toolbar .action-buttons {
  /* flex: 1; */
}

.page-toolbar .type-filter {
  min-width: 150px;
  margin-right: 20px;
}

.page-toolbar .date-pickers {
  align-self: center;
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: -7px;
}

.page-toolbar .date-pickers>div>div input[type=text] {
  margin-right: 10px;
  margin-left: 5px;
}

.react-datepicker__input-container input[type=text] {
  min-height: 38px;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%);
  padding-left: 10px;
  width: 115px;
}

.bookings-page {
  overflow: scroll;
  padding-top: 80px;
  padding-right: 0px;
}

.bookings-page .bookings-calendar {
  margin-top: 100px;
  height: calc(100vh - 250px);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.bookings-page .bookings-calendar::-webkit-scrollbar {
  display: none;
}

.bookings-page .bookings-table {
  max-width: none;
  position: relative;
}

.bookings-page .bookings-table td,
.bookings-page .bookings-table th {
  font-size: 14px;
  border: 1px solid #E7E7E7;
}
.bookings-page .bookings-table thead tr {
  background-color: white;
}
.bookings-page .bookings-table thead tr th:nth-child(1) {
  min-width: 230px;
}

.bookings-page .bookings-table thead th {
  font-weight: 500;
  padding: 0 5px;
  background-color: white;
  position: sticky;
  top: 99px;
  z-index: 3;
}

.bookings-page .bookings-table thead tr th:first-child,
.bookings-page .bookings-table tbody tr td:first-child {
  position: sticky;
  left: -20px;
  background-color: white;
  z-index: 4;
}

.bookings-page .bookings-table thead tr th:first-child {
  z-index: 6;
}

.bookings-page .bookings-table tbody tr:nth-child(even) td:first-child {
  background-color: #F2F2F2;
}

.bookings-page .bookings-table thead th.today {
  background-color: #f5dd90;
}

.bookings-page .bookings-table tbody svg {
  transform: rotate(0deg);
  transition-duration: 500ms;
  vertical-align: text-top;
}

.bookings-page .bookings-table tbody.open svg {
  transform: rotate(90deg);
  transition-duration: 500ms;
}

.bookings-page .bookings-table tbody:not(.open) tr.room-row {
  display: none;
}

.bookings-page .bookings-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.bookings-page .bookings-table tbody tr:first-child {
  font-weight: 500;
}

.bookings-page .bookings-table tbody tr td:nth-child(1) {
  padding: 0 5px;
}

.bookings-page .bookings-table tbody tr.room-row td:nth-child(1) {
  padding: 0 5px 0px 25px;
}

.bookings-page .bookings-table thead th span:nth-child(1),
.bookings-page .bookings-table thead th span:nth-child(3) {
  font-weight: 400;
}

.bookings-page .bookings-table thead span {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 1.2;
}

.bookings-page .bookings-table tbody td {
  height: 30px;
  min-width: 40px;
  max-width: 40px;
  position: relative;
  cursor: pointer;
}

.bookings-page .bookings-table.view-Week tbody td {
  min-width: 20px;
  max-width: 20px;
}

.bookings-page .bookings-table tbody td .booking-band.blocked, 
.bookings-page .bookings-table tbody td .booking-band.confirmed {
  margin: 0 20px;
  display: inline-block;
  width: calc(100% - 42px);
  height: 25px;
  position: absolute;
  top: 0px;
  padding: 2px 5px 0px 5px;
  z-index: -1;
  border-radius: 5px;
  cursor: pointer;
}

.bookings-page .bookings-table.view-Week tbody td .booking-band {
  margin: 0 10px;
  width: calc(100% - 21px);
}

.bookings-page .bookings-table tbody td .booking-band.confirmed.Team {
  background: linear-gradient(to bottom, #F5DD90 0%, #D5BD70 100%);
}
.bookings-page .bookings-table tbody td .booking-band.confirmed.Guest,
.bookings-page .bookings-table tbody td .booking-band.confirmed.Village {
  /* background: linear-gradient(to bottom, #F68E5F 0%, #D66E3F 100%); */
  background: linear-gradient(to bottom, #E6AE5F 0%, #C68E3F 100%);
}

.bookings-page .bookings-table tbody td .booking-band.blocked {
  /* background: linear-gradient(to bottom, rgba(230,230,230,1) 0%, rgba(210,210,210,1) 100%); */
  background: linear-gradient(to bottom, #E9E7E3 0%, #C9C7C3 100%);
}

.bookings-page .bookings-table tbody td .booking-band.offland {
  width: 120px;
}

.bookings-page .bookings-table .booking-cell.offland {
  background: repeating-linear-gradient(
    45deg,
    rgba(230,230,230,0.8),
    rgba(230,230,230,0.8) 3px,
    rgba(255,255,255,1) 3px,
    rgba(255,255,255,1) 25px
  );
  opacity: 0.3;
}

.bookings-page .bookings-table tbody td {
  position: relative;
}

.bookings-page .bookings-table tbody td>div {
  display: flex;
  position: absolute;
  top: 2px; 
  left: 0px;
  z-index: 1;
}
.bookings-page .bookings-table tbody td>div:nth-child(2) {
  top: 29px;
}
.bookings-page .bookings-table tbody td>div:nth-child(3) {
  top: 56px;
}

.bookings-page .bookings-table tbody td div.booking-cell {
  flex: 1 1 0px;
  height: 25px;
}

.bookings-page .bookings-table tbody td .booking-cell:hover {
  background-color: rgba(130,130,130,0.5);
}

.bookings-page .bookings-table tbody td .booking-info {
  position: sticky;
  left: 230px;
  min-width: 200px;
  color: rgba(0,0, 0, 0.7);
  font-style: italic;
  display: inline-block;
}

.bookings-page .bookings-table tbody td .cleaning-status {
  position: absolute;
  right: 3px;
}
.cleaning-status {
  padding: 2px 8px;
  margin-top: -2px;
  border-radius: 15px;
  color:white;
  text-transform: uppercase;
}
.cleaning-status.dirty {
  background-color: #AB2E26;
}
.cleaning-status.clean {
  background-color: #FF9301;
}
.cleaning-status.prepared {
  background-color: #7BB662;
}

.booking-edit-page .booking-form {
  max-width: 600px;
}

.booking-edit-page .booking-form h1, .my-booking-page .booking-form h1 {
  margin-bottom: 30px;
}

.booking-edit-page .booking-form .form-row, .my-booking-page .booking-form .form-row {
  margin-bottom: 20px;
}

.booking-edit-page .booking-form .form-row.room-row {
  display: flex;
  width: 100%;
  position: relative;
  gap: 10px;
  background-color: #F2F2F2;
  padding: 5px;
}

.booking-edit-page .booking-form .form-row.room-row>div>div {
  min-height: 40px;
}

.booking-edit-page .booking-form .form-row.room-row>div:nth-child(1),
.booking-edit-page .booking-form .form-row.room-row>div:nth-child(2) {
  max-width: 115px;
}

.booking-edit-page .booking-form .form-row.room-row>div:nth-child(3) {
  flex: 1 1 0px;
}

.booking-edit-page .booking-form .form-row.room-row .room-row-actions {
  position: absolute;
  right: -170px;
  width: 160px;
  top: 25px;
}

.form-field-info {
  font-size: 14px;
  font-style: italic;
  color: grey;
}

.booking-edit-page .booking-form .form-row.room-row input.form-check-input {
  transform: translateY(50%);
}

.booking-edit-page .booking-form .action-buttons button, .booking-details-page .action-buttons button {
  margin-right: 20px;
}

.booking-details-page h3, .booking-details-page h4 {
  margin: 25px 0px 15px 0px;
}
.booking-details-page .nav-tabs {
  margin-top: 40px;
}
.booking-details-page .tab-content {
  padding-left: 15px;
}
.booking-details-page .room-bookings-table {
  max-width: 765px;
}
.booking-details-page .offland-dates-table {
  margin-top: 30px;
  max-width: 550px;
}
.booking-details-page .payments-table {
  max-width: 750px;
}
.booking-details-page .room-bookings-table thead, .booking-details-page .payments-table thead {
  background-color: var(--thead-bg-color);
  color: white;
}
.booking-details-page .room-bookings-table .room-name {
  width: 235px;
}

.booking-details-page .room-bookings-table .room-name em {
  font-size: 14px;
}

.booking-details-page .room-bookings-table td {
  vertical-align: middle;
}

.booking-details-page .payments-table .btn-link {
  padding: 0px;
}
.booking-details-page .payments-table .btn-link {
  display: flex;
  align-items: center;
}
.booking-details-page .payments-table .refund-row td:first-child {
  padding-left: 30px;
}
.booking-details-page .payments-table .refund-row td:nth-child(2) {
  color: red;
}

.booking-details-page .billing-details-tab p>label {
  display: inline-block;
  min-width: 150px;
}

.booking-details-page .billing-address {
  display: flex;  
  gap: 5px;
  align-items: center;
}

.booking-details-page .billing-address>span {
  display: inline-block;
}

.my-booking-app {
  background-color: #F2F2F2;
}

.my-booking-page {
  max-width: 900px;
  margin: 0 auto;
}
.my-booking-page h1 {
  text-align: center;
}

.my-booking-page .react-datepicker-popper {
  z-index: 6;
}

.my-booking-page .page-header {
  background-color: #bfd9d4;
  padding-left: 0px !important;
  justify-content: center;
  height: 70px;
  max-width: 900px;
  left: auto;
  margin-left: -40px;
}
.my-booking-page .page-header img.mooji-logo {
  height: 80%;
}

.my-booking-page .welcome-my-booking {
  max-width: 700px;
  margin: 0 auto;
}

.my-booking-page .welcome-my-booking p>br {
  display: none;
}

.my-booking-page .welcome-my-booking-form {
  display: flex;
  flex-direction: column;
  padding-left: 25%;
}

.my-booking-page .action-buttons {
  padding: 30px 0px;
  text-align: center;
}

.my-booking-page .booking-form label.form-value {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.my-booking-page .booking-form .form-row.dates-row,
.my-booking-page .booking-form .form-row.details-row {
  display: flex;
  gap: 20px;
}

.my-booking-page .booking-form .form-row.dates-row>div,
.my-booking-page .booking-form .form-row.details-row>div {
  min-width: 50%;
}

.my-booking-page .booking-form .room-type-choice {
  width: 100%;
}

.my-booking-page .booking-form .room-type-choice .room-type-card {
  display: flex;
  align-items: center;
  gap: 20px;
  box-shadow: 0px 3px 20px rgb(0, 0, 0, 0.4);
  margin: 30px 0px;
  cursor: pointer;
}

.my-booking-page .booking-form .room-type-card .room-type-photo {
  width: 285px;
  /* height: 190px; */
  overflow: hidden;
}

.my-booking-page .booking-form .room-type-card .room-type-photo>img {
  width: 100%;
}

.image-gallery-right-nav svg,
.image-gallery-left-nav svg {
  width: 30px !important;
  height: 60px !important;
}


.my-booking-page .booking-form .room-type-card .room-type-info {
  flex: 1 1 0px;
  margin: 10px 0px;
}

.my-booking-page .booking-form .room-type-card .room-type-info>div {
  font-size: 14px;
}

.my-booking-page .booking-form .room-type-card .room-type-info>div p {
  margin-bottom: 0px;
}

.my-booking-page .booking-form .room-type-card .room-type-price {
  width: 130px;
}

.my-booking-page .booking-form .room-type-card h3 {
  color: black;
}
.my-booking-page .booking-form .room-type-card h4 {
  color: black;
}

.my-booking-page .booking-form .room-type-choice .form-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.my-booking-page .booking-form .room-type-choice .form-check input[type=radio] {
  margin: 0 auto 10px auto;
}
.my-booking-page .booking-form .room-type-choice .form-check input[type=radio]:checked {
  background-color: var(--thead-bg-color);
}

.progressbar {
  counter-reset: step;
  height: 120px;
  padding-left: 0px;
}
.progressbar li.active {
  color: #374648;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 16px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #879698;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #83a3a7;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  position: relative;
  z-index: 1;
}
.progressbar li.active:before {
  border-color: #374648;
  background-color: #F68E5F;
  color: black;
}

.progressbar li:not(:last-child):after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #83a3a7;
  top: 15px;
  left: 50%;
  z-index: 0;
}

.registration-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.registration-form>p {
  width: 100%;
}

.registration-form h2 {
  width: 100%;
  border-bottom: 1px solid #D2D2D2;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.registration-form>div {
  width: 49%;
  margin-bottom: 20px;
  margin-top: 10px;
}

form.was-validated div.invalid div[class$="-control"] {
  border-color: #dc3545;
}
form.was-validated div.invalid::after  {
  content: ' ';
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  width: calc(0.75em + 0.375rem);
  height: calc(0.75em + 0.375rem);
  top: 10px;
  right: 35px;
  position: absolute;
}

form.was-validated div.valid::after {
  content: ' ';
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  width: calc(0.75em + 0.375rem);
  height: calc(0.75em + 0.375rem);
  top: 10px;
  right: 35px;
  position: absolute;
}

form label.required {
  position: relative;
}

form label.required::after {
  content: '*';
  color: red;
  position: absolute;
  right: -10px;
  top: 0px;
}

form.was-validated .form-check-input:valid {
  border-color: rgba(0,0,0,.25);
}
form.was-validated .form-check-input:valid~.form-check-label {
  color: black;
}

form .react-datepicker__input-container input[type=text] {
  width: 100%;
}

.registration-form .dietary-choices {
  display: flex;
  width: 100%;
  gap: 30px;
}
.registration-form .terms {
  display: flex;
  width: 100%;
}

.my-booking-page .booking-form #payment-form {
  margin-top: 30px;
  width: 30vw;
  min-width: 820px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 10px;
}

.billing-address-fields, .guest-details-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.billing-address-fields>div, .guest-details-fields>div {
  width: 49%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.booking-form #payment-form h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.billing-address-fields .street-address {
  width: 100%;  
}
.billing-address-fields .street-address>div {
  display: flex;
  justify-content: space-between;
}
.billing-address-fields .street-address>div input {
  width: 49%;
}

.payment-element {
  margin-bottom: 24px;
}

.payment-message {
  color: #dc3545;
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.pay-button, .submit-booking-button {
  background: #586BA4;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.submit-booking-button {
  margin-top: 30px;
}

.pay-button:hover, .submit-booking-button:hover {
  filter: contrast(135%);
}

.pay-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.pay-spinner,
.pay-spinner:before,
.pay-spinner:after {
  border-radius: 50%;
}

.pay-spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.pay-spinner:before,
.pay-spinner:after {
  position: absolute;
  content: '';
}

.pay-spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.pay-spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 80vw;
    min-width: initial;
  }
}

.welcome-my-booking h2 {
  margin-top: 30px;
}

.welcome-my-booking table.fancy {
  width: 100%;
  max-width: 550px;
  margin-bottom: 30px;
  margin-top: -10px;
}

.welcome-my-booking table.fancy td {
  border: 1px solid #ccc;
  padding: 5px;
}

.my-booking-offland-dates-page .table-actions {
  text-align: center;
}
.my-booking-offland-dates-page p.form-info {
  max-width: 600px;
  margin: 20px auto;
}

.offland-dates-table thead {
  background-color: var(--thead-bg-color);
  color: white;
}

.add-offland-dates-modal .form-row.dates-row {
  display: flex;
  gap: 20px;
}

.booking-menu-popover button {
  font-size: 14px;
}

.edit-booking-form .form-row {
  margin-bottom: 20px;
}

.change-room-booking-form .form-row {
  margin-bottom: 20px;
}

.change-cleaning-status-modal .modal-content {
  max-width: 400px;
}

.change-cleaning-status-form .form-row {
  margin-bottom: 20px;
}

.change-room-booking-dates-form .form-row.dates-row,
.change-room-booking-form .form-row.dates-row {
  display: flex;
}

p.error {
  color: red;
}

.add-payment-form .multiple-fields-row {
  display: flex;
}

.add-payment-form .multiple-fields-row>div {
  flex: 1 1 0px;
}

.add-payment-form .multiple-fields-row input[type=text],
.add-payment-form .multiple-fields-row input[type=number]  {
  max-width: 80%;
}

.payments-due-page .data-table-container {
  max-width: 950px;
  min-width: 650px;
}

.payments-due-bookings-table thead tr {
  background-color: var(--thead-bg-color);
  color: white;
}

.payments-due-bookings-table button {
  display: block;
}
.payments-due-bookings-table button:nth-child(2) {
  margin-top: 10px;
}

.payments-due-page .nav-tabs {
  margin-top: 10px;
}
.payments-due-page .form-filters {
  margin-bottom: 40px;
}
.payments-due-page .tab-info-text {
  margin-top: 30px;
  font-style: italic;
  margin-bottom: 20px;
}
.payments-due-page .tab-info-text span {
  color: var(--bs-link-color);
  font-weight: bold;
}
.card-payment-failed-msg {
  font-style: italic;
  font-size: 80%;
  color: red;
}

.reports-page .nav-tabs {
  margin-top: 10px;
}

.reports-page .offland-dates-tab {
  padding-top: 20px;
}

.reports-page .tab-info-text {
  margin-top: 30px;
  font-style: italic;
  margin-bottom: 20px;
}

.reports-page .tab-info-text span {
  color: var(--bs-link-color);
  font-weight: bold;
}

.reports-page .data-table-container {
  max-width: 600px;
}

.reports-page .offland-dates-tab .data-table-container {
  min-width: 400px;
}

.reports-page .occupancy-report-tab .data-table-container,
.reports-page .rates-report-tab .data-table-container,
.reports-page .los-report-tab .data-table-container {
  max-width: unset;
}

.reports-page table thead tr {
  background-color: var(--thead-bg-color);
  color: white;
}

.reports-page .occ-type-filter {
  min-width: 250px;
}

.settings-page .nav-tabs {
  margin-top: 10px;
}

.settings-page .tab-content {
  padding-top: 20px;
  max-width: 800px;
}

.settings-page form>div {
  margin-bottom: 20px;
}

.settings-page form input[type=number] {
  width: 120px;
}

.settings-page .action-buttons {
  margin-top: 30px;
}

.settings-page .quill .ql-container {
  min-height: 200px;
}

.settings-page .calendar-period-row {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.settings-page .calendar-period-row .react-datepicker-wrapper {
  margin-right: -20px;
}

.rooms-page .data-table-container {
  max-width: 650px;
  min-width: 650px;
}

.rooms-page .location-filter {
  padding-right: 10px;
  max-width: 250px;
}

.rooms-page .room-type-filter {
  max-width: 250px;
}

.rooms-page .rooms-table thead tr {
  background-color: var(--thead-bg-color);
  color: white;
}

.locations-page .data-table-container {
  max-width: 400px;
  min-width: 300px;
}

.room-types-page .data-table-container {
  width: 1000px;
}

.offland-dates-tab .data-table-container {
  max-width: 600px;
}

.offland-dates-tab .table-filters>div,
.occupancy-report-tab .table-filters>div {
  width: 130px;
}

@media(min-width: 576px) {
  .add-location-modal .modal-dialog {
    max-width: 300px;
  }
  .add-rate-group-modal .modal-dialog {
    max-width: 600px;
  }
  .add-offland-dates-modal .modal-dialog {
    max-width: 450px;
  }
}

.locations-table td {
  vertical-align: middle;
}

.table-actions {
  margin-bottom: 15px;
}
.table-actions button svg {
  width: 20px;
  height: 20px;
  vertical-align: -4px;
}

table .row-actions {
  width: 115px;
}

table .row-actions>button {
  margin-right: 5px;
}

.rates-page .rates-table .row-actions {
  width: 170px;
  min-width: 170px;
}

.add-room-type-form>div, .add-room-form>div, .add-rate-group-form>div {
  margin-bottom: 20px;
}

.add-room-type-form .form-field-info {
  margin-bottom: 10px;
}

.add-room-type-form .quill {
  height: 120px;
  margin-bottom: 60px;
}

.add-room-type-form .photo-previews {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}
.add-room-type-form .photo-previews>div {
  width: calc(33% - 6px);
  position: relative;
}

.add-room-type-form .photo-previews>div>img {
  width: 100%;
}
.add-room-type-form .btn-add-photos {
  margin-top: 10px;
}

.add-room-type-form .photo-previews>div .btn-remove {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: white;
  display: none;
}
.add-room-type-form .photo-previews>div:hover .btn-remove {
  display: block;
}

.room-types-table tbody tr td.room-type-name {
  min-width: 120px;
}
.room-types-table tbody tr td.room-type-photo {
  min-width: 120px;
  max-width: 250px;
  padding: 0px;
}
.room-types-table tbody tr td.room-type-description {
  width: 330px;
  font-size: 14px; 
  color: grey;
}
.room-types-table tbody tr td.room-type-description p {
  margin-bottom: 0px;
}
.room-types-table tbody tr td.room-type-stats {
  min-width: 180px;
  font-size: 14px;
}
.room-types-table tbody tr td.room-type-stats .room-type-location {
  font-weight: bold;
}

.room-types-table tbody td h4 {
  display: inline-block;
}

.add-rate-group-form .room-type-rate-row {
  display: flex;
  gap: 10px;
  min-height: 40px;
}

.add-rate-group-form .room-type-rate-row:not(.room-type-rate-header) {
  align-items: center;
}

.add-rate-group-form .room-type-rate-header {
  font-weight: 500;
}
.add-rate-group-form .room-type-rate-row>* {
  width: 16.66%;
}
.add-rate-group-form .room-type-rate-row>*:nth-child(1) {
  flex: 1 1 0px;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 5px;
  top: 15px;  
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  overflow: hidden !important;
  /* padding: 2.5em 1.5em 0; */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


.bm-item-list {
  list-style-type: none;
  padding: 40px 0px 0px 0px;
  width: 100%;
}

.bm-item-list a {
  
  color: white !important;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.bm-item-list a.l1 {
  padding: 7px 20px;
}

.bm-item-list a.l2 {
  padding: 5px 20px 5px 40px;
  font-size: 15px;
}

.bm-item-list a:not(.logo):not(.active):hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.bm-item-list a.active {
  background-color: #586BA4;
}

.login-app {
  background-color: #F2F2F2;
}

.login-page {
  background-color: #F2F2F2;
  display: flex;
  background: radial-gradient(circle at center, #F2F2F2 0%, #F5DD90 80%);
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 240px;
  height: 220px;
  /* //margin: calc(25vh) auto 0 auto; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 11px 2px rgb(0, 0, 0, 0.1);
}

.login-box>img {
  width: 200px;
}

a.social-sign-in.google {
  background: url(https://shop.mooji.org/wp-content/themes/shopmoojiorg/img/svg/google-logo.svg) no-repeat;
  background-size: 25px 25px;
  background-position: 10px 12px;
  width: 200px;
  height: 51px;
  margin: 20px 20px 0px 20px;
  display: inline-block;
  color: rgb(66, 82, 110);
  background-color: white !important;
  vertical-align: middle;
  border-radius: 11px;
  border: 1px solid #DED7D3;  
}

a.social-sign-in.google:hover {
  background-color: #FBFBFB !important;
}

a.social-sign-in span {
    line-height: 51px;
    vertical-align: top;
    width: 100%;
    padding-left: 20px;
    text-align: center;
    display: inline-block;
}

.page-header {
  width: 100%;
  background-color: #F2F2F2;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 50px;
  display: flex;
  z-index: 5;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}

body.menu .page-header {
  padding-left: calc(var(--nav-width) + 40px);
}

.page-header h1 {
  margin-bottom: 0px;
}

label.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

label.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

label.toggle span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsl(0, 0%, 50%);;
  transition: 0.3s;
  border-radius: 25px;
}

label.toggle span:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

label.toggle input:checked + span {
  background-color: var(--thead-bg-color);
}

label.toggle input:checked + span:before {
  transform: translateX(24px);
}

label.toggle strong {
  position: absolute;
  left: 100%;
  font-weight: 400;
  color: hsl(0, 0%, 50%);
  width: max-content;
  height: 100%;
  line-height: 25px;
  margin-left: 5px;
  cursor: pointer;
}

.open-booking-page .booking-form .form-row.dates-row>div {
  min-width: auto;
}

.open-booking-page .booking-form .form-row.dates-row {
  justify-content: center;
  align-items: end;
  flex-wrap: wrap;
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.open-booking-page .your-selection {
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
}

.open-booking-page .booking-form .form-row.dates-row>div:nth-child(1),
.open-booking-page .booking-form .form-row.dates-row>div:nth-child(2) {
  width: 120px;
}

@media (min-width: 769px) {
  body.menu .page-container:not(.my-booking-page):not(.login-page) {
    width: calc(100% - var(--nav-width) + 10px);
    transform: translate3d(var(--nav-width), 0, 0); transition: all 0.5s ease 0s; /* for Safari */
  }
  body.menu .page-toolbar,
  body.menu .page-filters {
    padding-left: 20px;
    width: calc(100% - var(--nav-width));
    transform: translate3d(var(--nav-width), 0px, 0px); 
    transition: all 0.5s ease 0s;
  }
  body.menu .bm-menu-wrap {
    display: block !important; /* for Safari */
  }
  .bm-cross-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .bm-burger-button {
    top: 15px;
    left: 20px;
  }
  .bookings-app .bm-burger-button {
    top: 27px;
    left: 10px;
  }
  .page-container {
    padding: 70px 20px 40px 20px;
  }
  .page-container.bookings-page, .page-container.my-booking-page {
    padding: 40px 20px 40px 20px;
  }
  .page-toolbar {
    flex-wrap: wrap;
    width: 100%;
    padding-right: 10px;
    padding-left: 50px;
  } 
  .page-toolbar .date-pickers {
    flex: 1 1 0px;
  }

  .page-toolbar .date-pickers>div>div input[type=text] {
    margin-right: 0px;
  }

  .page-filters {
    flex-wrap: wrap;
    width: 100%;
    top: 160px;
    padding-left: 20px;
  }

  .bookings-app {
    flex-direction: column;
  }

  .bookings-page {
    overflow: initial;
  }

  .bookings-app .page-filters {
    position: relative;
    min-width: 1000px;
  }

  .bookings-page .bookings-table thead th {
    top: 166px;
  }
  .bookings-page .bookings-table thead tr th:first-child, .bookings-page .bookings-table tbody tr td:first-child {
    left: -1px;
  }

  .my-booking-page {
    padding: 20px;
  }

  .my-booking-page .booking-form #payment-form {
    min-width: auto;
    width: 100%;
  }

  .registration-form>div {
    width: 100%;
  }

  .registration-form .dietary-choices {
    flex-direction: column;
  }

  .my-booking-page .booking-form .room-type-choice .room-type-card {
    flex-direction: column;
  }

  .my-booking-page .booking-form .room-type-card .room-type-photo {
    width: 100%;
    height: calc(100vw / 1.7);
  }

  .my-booking-page .booking-form .room-type-card .room-type-info {
    padding: 0 20px;
  }

  .bookings-page .bookings-calendar {
    margin-top: 140px;
  }

  .bookings-page .bookings-table thead tr th:nth-child(1) {
    min-width: 150px;
  }

  .bookings-page .bookings-table tbody td .booking-info {
    left: 150px;
  }

  .booking-edit-page .booking-form .form-row.room-row {
    flex-wrap: wrap;
  }

  .booking-edit-page .booking-form .form-row.room-row>div:first-child, .booking-edit-page .booking-form .form-row.room-row>div:nth-child(2) {
    max-width: 50%;
  }

  .progressbar {
    height: 50px;
  }

  .progressbar li>span {
    display: none;
  }

  .page-header {
    justify-content: center;
    padding-left: 0px;
  }

  body.menu .page-header {
    padding-left: 0px;
  }

  .my-booking-page .page-header {
    margin-left: -20px;
  }
  .my-booking-page.with-page-header {
    padding-top: 90px;
  }

  .rates-page .rates-table thead tr th:nth-child(1),
  .rates-page .rates-table tbody tr td:nth-child(1) {
    left: -21px;
  }
}
